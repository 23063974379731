/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron_left_double': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.41 7.41L18 6l-6 6 6 6 1.41-1.41L14.83 12l4.58-4.59z"/><path pid="1" d="M12.41 7.41L11 6l-6 6 6 6 1.41-1.41L7.83 12l4.58-4.59z"/>'
  }
})
